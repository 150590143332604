import React, { useEffect, useState, useCallback } from "react";
import { supabase } from "../supabase/client";

const socialIcons = {
  instagram: (props) => (
    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
        clipRule="evenodd"
      />
    </svg>
  ),
  bluesky: (props) => (
    <svg
      role="img"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      id="Bluesky--Streamline-Simple-Icons"
      {...props}
    >
      <desc>Bluesky Streamline Icon: https://streamlinehq.com</desc>
      <title>Bluesky</title>
      <path
        d="M12 10.8c-1.087 -2.114 -4.046 -6.053 -6.798 -7.995C2.566 0.944 1.561 1.266 0.902 1.565 0.139 1.908 0 3.08 0 3.768c0 0.69 0.378 5.65 0.624 6.479 0.815 2.736 3.713 3.66 6.383 3.364 0.136 -0.02 0.275 -0.039 0.415 -0.056 -0.138 0.022 -0.276 0.04 -0.415 0.056 -3.912 0.58 -7.387 2.005 -2.83 7.078 5.013 5.19 6.87 -1.113 7.823 -4.308 0.953 3.195 2.05 9.271 7.733 4.308 4.267 -4.308 1.172 -6.498 -2.74 -7.078a8.741 8.741 0 0 1 -0.415 -0.056c0.14 0.017 0.279 0.036 0.415 0.056 2.67 0.297 5.568 -0.628 6.383 -3.364 0.246 -0.828 0.624 -5.79 0.624 -6.478 0 -0.69 -0.139 -1.861 -0.902 -2.206 -0.659 -0.298 -1.664 -0.62 -4.3 1.24C16.046 4.748 13.087 8.687 12 10.8Z"
        fill="currentColor"
        strokeWidth="1"
      ></path>
    </svg>
  ),
};

export function OrgsView() {
  const [organizations, setOrganizations] = useState({});
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all"); // "all", "advocacy", "social"

  const fetchOrganizations = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from("bike_organizations")
        .select("*")
        .order("name");

      if (error) {
        console.error("Error fetching organizations:", error);
        return;
      }

      // Filter organizations based on type
      const filteredData = data.filter((org) => {
        if (filter === "all") return true;
        return org.type_of_group === filter || org.type_of_group === "both";
      });

      // Group organizations by state
      const grouped = filteredData.reduce((acc, org) => {
        const state =
          !org.state || org.is_nationwide ? "Nationwide" : org.state;
        if (!acc[state]) {
          acc[state] = [];
        }
        // Transform the data to match our component's structure
        acc[state].push({
          name: org.name,
          website: org.website_url,
          city: org.city || null,
          socials: {
            ...(org.instagram_url && { instagram: org.instagram_url }),
            ...(org.bluesky_url && { bluesky: org.bluesky_url }),
          },
          email: org.email,
          calendar: org.calendar_url,
        });
        return acc;
      }, {});

      setOrganizations(grouped);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  }, [filter]); // Include filter in dependencies since it's used inside

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]); // Now fetchOrganizations is properly memoized

  if (loading) {
    return (
      <div className="bg-white px-6 py-12 md:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-4xl">
            Loading organizations...
          </h1>
        </div>
      </div>
    );
  }

  // Map of state codes to full names
  const stateNames = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
    DC: "District of Columbia",
  };

  // Get full state name or return original if it's "Nationwide"
  const getStateName = (stateCode) => {
    if (stateCode === "Nationwide") return stateCode;
    return stateNames[stateCode] || stateCode;
  };

  // Sort states alphabetically, but keep "Nationwide" at the end
  const sortedStates = Object.keys(organizations || {}).sort((a, b) => {
    if (a === "Nationwide") return 1;
    if (b === "Nationwide") return -1;
    return getStateName(a).localeCompare(getStateName(b));
  });

  return (
    <div>
      <div className="bg-white px-6 py-12 md:py-32 lg:px-8">
        <div className="mx-auto max-w-3xl">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-4xl">
            Bike Organizations
          </h1>
          <p className="mt-6 text-xl leading-8 text-gray-700">
            Find a local advocacy org, or a social group to go out riding with.
            Either way, have fun on your bike and make some new friends!
          </p>

          <p className="mt-4 text-sm text-gray-600 text-center">
            Want to get your organization listed? Email{" "}
            <a
              href="mailto:gobikecamping@gmail.com"
              className="text-blue-600 hover:text-blue-800"
            >
              gobikecamping@gmail.com
            </a>
          </p>

          <div className="mt-8 flex flex-wrap justify-center gap-6">
            <div className="flex items-center">
              <input
                type="radio"
                id="all"
                name="filter"
                value="all"
                checked={filter === "all"}
                onChange={(e) => setFilter(e.target.value)}
                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
              />
              <label
                htmlFor="all"
                className="ml-2 text-sm font-medium text-gray-900"
              >
                All Groups
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="advocacy"
                name="filter"
                value="advocacy"
                checked={filter === "advocacy"}
                onChange={(e) => setFilter(e.target.value)}
                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
              />
              <label
                htmlFor="advocacy"
                className="ml-2 text-sm font-medium text-gray-900"
              >
                Advocacy
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="social"
                name="filter"
                value="social"
                checked={filter === "social"}
                onChange={(e) => setFilter(e.target.value)}
                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
              />
              <label
                htmlFor="social"
                className="ml-2 text-sm font-medium text-gray-900"
              >
                Social
              </label>
            </div>
          </div>

          <div className="mt-10">
            {sortedStates.map((state) => {
              // Sort organizations by city (null cities at bottom) and then by name
              const sortedOrgs = organizations[state].sort((a, b) => {
                // If both have cities or both don't have cities, sort by city then name
                if ((!a.city && !b.city) || (a.city && b.city)) {
                  const cityCompare = (a.city || "").localeCompare(
                    b.city || ""
                  );
                  return cityCompare !== 0
                    ? cityCompare
                    : a.name.localeCompare(b.name);
                }
                // If only one has a city, put the one without a city at the bottom
                return a.city ? -1 : 1;
              });

              return (
                <div key={state} className="mb-12">
                  <h2 className="text-xl font-semibold text-gray-900 border-b border-gray-200 pb-2 sm:text-2xl">
                    {getStateName(state)}
                  </h2>
                  <table className="mt-4 w-full">
                    <tbody className="divide-y divide-gray-200">
                      {sortedOrgs.map((org) => (
                        <tr key={org.name} className="hover:bg-gray-50">
                          <td className="py-4 pr-3">
                            <a
                              href={org.website}
                              className="text-blue-600 hover:text-blue-800 font-medium text-sm sm:text-base"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {org.name}
                              {org.city && (
                                <span className="text-gray-500 font-normal text-xs sm:text-sm">
                                  {" "}
                                  ({org.city})
                                </span>
                              )}
                            </a>
                          </td>
                          <td className="px-2 py-3 sm:px-3 sm:py-4">
                            <div className="flex space-x-4">
                              {Object.entries(org.socials).map(
                                ([platform, url]) => (
                                  <a
                                    key={platform}
                                    href={url}
                                    className="text-gray-500 hover:text-gray-700 text-sm sm:text-base"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="sr-only">{platform}</span>
                                    {socialIcons[platform]({
                                      className: "h-4 w-4 sm:h-5 sm:w-5",
                                    })}
                                  </a>
                                )
                              )}
                            </div>
                          </td>
                          <td className="px-3 py-4">
                            <a
                              href={`mailto:${org.email}`}
                              className="text-gray-600 hover:text-gray-900 text-sm sm:text-base"
                            >
                              {org.email}
                            </a>
                          </td>
                          <td className="pl-3 py-4">
                            {org.calendar && (
                              <a
                                href={org.calendar}
                                className="text-gray-600 hover:text-gray-900 text-sm sm:text-base"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Calendar
                              </a>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
