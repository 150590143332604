import { useState, useRef } from "react";
import { supabase } from "../supabase/client";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Radio,
  RadioGroup,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Map, { NavigationControl, Marker } from "react-map-gl";

const weldingOptions = ["?", "Yes", "No"];
const bikeBoxesOptions = ["?", "Yes", "No"];
const touringTiresOptions = ["?", "Yes", "No"];
const touringGearOptions = ["?", "Yes", "No"];
const emergencyServiceOptions = [
  "?",
  "Yes - 24/7",
  "Yes - Limited Hours",
  "No",
];
const bikeRentalOptions = ["?", "Yes", "No"];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BikeShopForm({
  marker,
  selectedCountry,
  selectedState,
  setSelectedCountry,
  setSelectedState,
  user,
  setShowForm,
  editLocation,
  countries,
  usStates,
  provinces,
}) {
  const mapRef = useRef(null);
  const [spotName, setSpotName] = useState("");
  const [spotDesc, setSpotDesc] = useState("");
  const [spotWebsite, setSpotWebsite] = useState("");
  const [spotPhone, setSpotPhone] = useState("");
  const [spotAddress, setSpotAddress] = useState("");

  const [nameError, setNameError] = useState();
  const [descError, setDescError] = useState();
  const [websiteError, setWebsiteError] = useState();
  const [addressError, setAddressError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [submissionError, setSubmissionError] = useState();

  // Bike shop specific state
  const [weldingOptionsVal, setWeldingOptionsVal] = useState(weldingOptions[0]);
  const [bikeBoxesOptionsVal, setBikeBoxesOptionsVal] = useState(
    bikeBoxesOptions[0]
  );
  const [touringTiresOptionsVal, setTouringTiresOptionsVal] = useState(
    touringTiresOptions[0]
  );
  const [touringGearOptionsVal, setTouringGearOptionsVal] = useState(
    touringGearOptions[0]
  );
  const [emergencyServiceOptionsVal, setEmergencyServiceOptionsVal] = useState(
    emergencyServiceOptions[0]
  );
  const [bikeRentalOptionsVal, setBikeRentalOptionsVal] = useState(
    bikeRentalOptions[0]
  );

  const translateValues = (val) => {
    if (val === "Yes") {
      return true;
    }
    if (val === "No") {
      return false;
    }
    if (val === "?") {
      return null;
    }
    return val.toLowerCase();
  };

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!urlPattern.test(urlString);
  };

  const cleanPhoneNumber = (phone) => {
    const digitsOnly = phone.replace(/[^\d]/g, "");
    return digitsOnly;
  };

  const validateForm = () => {
    let hasError = false;

    if (spotName.length < 5) {
      setNameError("Name must be more than 5 characters long.");
      hasError = true;
    } else if (spotName.length > 120) {
      setNameError("Name must be less than 120 characters.");
      hasError = true;
    } else {
      setNameError("");
    }

    if (spotDesc.length < 40) {
      setDescError(
        "Description needs to be more than 40 characters. It is currently at: " +
          spotDesc.length
      );
      hasError = true;
    } else if (spotDesc.length > 950) {
      setDescError(
        "Description needs to be less than 950 characters. It is currently at: " +
          spotDesc.length
      );
      hasError = true;
    } else {
      setDescError("");
    }

    if (spotWebsite.length > 0 && !isValidUrl(spotWebsite)) {
      setWebsiteError("Not a valid URL.");
      hasError = true;
    } else if (spotWebsite.length > 200) {
      setWebsiteError("URL is too long. Must be less than 200 characters.");
      hasError = true;
    } else {
      setWebsiteError("");
    }

    if (spotAddress.length === 0) {
      setAddressError("Please include an address.");
    }

    if (spotPhone && cleanPhoneNumber(spotPhone).length !== 10) {
      setPhoneError(
        "Not a valid phone number. Don't include the country code."
      );
      hasError = true;
    } else {
      setPhoneError("");
    }

    if (hasError) {
      setSubmissionError("Fix the errors above.");
    } else {
      setSubmissionError("");
    }

    return hasError;
  };

  async function submitSpot(e) {
    if (validateForm()) {
      return;
    }

    e.preventDefault();
    const { data, error } = await supabase
      .from("bike_shops")
      .insert([
        {
          name: spotName,
          state: selectedState.code,
          description: spotDesc,
          phone_number: spotPhone ? cleanPhoneNumber(spotPhone) : null,
          address: spotAddress,
          latitude: marker.lat,
          longitude: marker.lng,
          website_url: spotWebsite,
          submitted_by: user.id,
          can_weld: translateValues(weldingOptionsVal),
          has_bike_boxes: translateValues(bikeBoxesOptionsVal),
          has_touring_tires: translateValues(touringTiresOptionsVal),
          has_touring_gear: translateValues(touringGearOptionsVal),
        },
      ])
      .select();

    if (error) {
      setSubmissionError(
        "There was an error with the submission: " + error.message
      );
      setTimeout(() => {
        setSubmissionError(null);
      }, 10000);
    }

    if (data) {
      setShowForm(false);
    }
  }

  return (
    <div className="bg-white px-2 md:px-6 py-12 lg:px-8 md:w-9/12 mx-auto">
      <h1 className="text-4xl font-bold pb-4">Add New Location</h1>
      <form>
        <div className="space-y-12">
          <div className="pb-12">
            <h2 className="text-3xl text-gray-700 font-bold">General</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be visible by other cyclists. Please make
              things as detailed as possible.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Shop Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      value={spotName}
                      onChange={(e) => setSpotName(e.target.value)}
                      id="name"
                      name="name"
                      type="text"
                      className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p id="name-error" className="mt-2 text-sm text-red-600">
                    {nameError} &nbsp;
                  </p>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="desc"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    id="desc"
                    name="desc"
                    value={spotDesc}
                    onChange={(e) => setSpotDesc(e.target.value)}
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  Include any information cyclists would want to know, like
                  repair services, parts availability, and any specific
                  accommodations for touring cyclists.
                </p>
                <p id="desc-error" className="mt-2 text-sm text-red-600">
                  {descError}
                </p>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="website"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Shop Website
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="website"
                      name="website"
                      value={spotWebsite}
                      onChange={(e) => setSpotWebsite(e.target.value)}
                      type="url"
                      className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p id="url-error" className="mt-2 text-sm text-red-600">
                    {websiteError}
                  </p>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Phone Number
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="phone"
                      name="phone"
                      type="tel"
                      value={spotPhone}
                      onChange={(e) => setSpotPhone(e.target.value)}
                      className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p id="phone-error" className="mt-2 text-sm text-red-600">
                    {phoneError}
                  </p>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label
                  htmlFor="website"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Address
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      id="address"
                      name="address"
                      value={spotAddress}
                      onChange={(e) => setSpotAddress(e.target.value)}
                      className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p id="address-error" className="mt-2 text-sm text-red-600">
                    {addressError}
                  </p>
                </div>
              </div>

              <div className="sm:col-span-4 sm:max-w-md">
                <Listbox
                  value={selectedCountry}
                  onChange={(country) => {
                    setSelectedCountry(country);
                    // Reset state/province when country changes
                    if (country.code === "USA") {
                      setSelectedState(usStates[0]);
                    } else if (country.code === "CA") {
                      setSelectedState(provinces[0]);
                    } else {
                      setSelectedState({ name: "Select...", code: "fake" });
                    }
                  }}
                >
                  <Label className="block text-sm font-medium leading-6 text-gray-900">
                    Country
                  </Label>
                  <div className="relative mt-2">
                    <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                      <span className="block truncate">
                        {selectedCountry.name}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-gray-400"
                        />
                      </span>
                    </ListboxButton>

                    <ListboxOptions
                      transition
                      className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                    >
                      {countries.map((country) => (
                        <ListboxOption
                          key={country.code}
                          value={country}
                          className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                        >
                          <span className="block truncate font-normal group-data-[selected]:font-semibold">
                            {country.name}
                          </span>

                          <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                            <CheckIcon aria-hidden="true" className="h-5 w-5" />
                          </span>
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  </div>
                </Listbox>
              </div>

              {selectedCountry.code !== "fake" && (
                <div className="sm:col-span-4 sm:max-w-md">
                  <Listbox value={selectedState} onChange={setSelectedState}>
                    <Label className="block text-sm font-medium leading-6 text-gray-900">
                      {selectedCountry.code === "USA" ? "State" : "Province"}
                    </Label>
                    <div className="relative mt-2">
                      <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span className="block truncate">
                          {selectedState.name}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                          />
                        </span>
                      </ListboxButton>

                      <ListboxOptions
                        transition
                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                      >
                        {(selectedCountry.code === "USA"
                          ? usStates
                          : provinces
                        ).map((state) => (
                          <ListboxOption
                            key={state.code}
                            value={state}
                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                          >
                            <span className="block truncate font-normal group-data-[selected]:font-semibold">
                              {state.name}
                            </span>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            </span>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                  </Listbox>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="space-y-12">
          <div className="pb-12">
            <h2 className="text-3xl text-gray-700 font-bold">Location</h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-8">
              <div className="sm:col-span-4">
                <p>
                  This is how the shop will appear on the map. If it's wrong,
                  please change it.
                </p>

                <button
                  type="button"
                  onClick={() => editLocation()}
                  className="w-full md:w-32 rounded-md bg-blue-500 px-3.5 py-2.5 my-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Edit location
                </button>
                <br />
                <label
                  htmlFor="latitude"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Latitude
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      value={marker.lat}
                      disabled={true}
                      id="latitude"
                      name="latitude"
                      type="text"
                      className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <br />
                <label
                  htmlFor="longitude"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Longitude
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      value={marker.lng}
                      disabled={true}
                      id="longitude"
                      name="longitude"
                      type="text"
                      className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-4 h-96">
                <Map
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                  reuseMaps
                  width="100%"
                  mapStyle="mapbox://styles/mapbox/streets-v11"
                  initialViewState={{
                    longitude: marker.lng,
                    latitude: marker.lat,
                    zoom: 13,
                  }}
                  ref={mapRef}
                >
                  <NavigationControl style={{ marginRight: "25px" }} />
                  <Marker latitude={marker.lat} longitude={marker.lng} />
                </Map>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-3xl text-gray-700 font-bold">Services</h2>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Frame Welding</p>
          </div>
          <div className="col-span-6">
            <fieldset
              className="cursor-default"
              aria-label="Choose a memory option"
            >
              <RadioGroup
                value={weldingOptionsVal}
                onChange={setWeldingOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {weldingOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === weldingOptions.length - 1 ? "rounded-r-md" : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Bike Boxes</p>
          </div>
          <div className="col-span-6">
            <fieldset
              className="cursor-default"
              aria-label="Choose a memory option"
            >
              <RadioGroup
                value={bikeBoxesOptionsVal}
                onChange={setBikeBoxesOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {bikeBoxesOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === bikeBoxesOptions.length - 1
                        ? "rounded-r-md"
                        : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Touring Tires</p>
          </div>
          <div className="col-span-6">
            <fieldset
              className="cursor-default"
              aria-label="Choose a memory option"
            >
              <RadioGroup
                value={touringTiresOptionsVal}
                onChange={setTouringTiresOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {touringTiresOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === touringTiresOptions.length - 1
                        ? "rounded-r-md"
                        : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Touring Gear</p>
          </div>
          <div className="col-span-6">
            <fieldset
              className="cursor-default"
              aria-label="Choose a memory option"
            >
              <RadioGroup
                value={touringGearOptionsVal}
                onChange={setTouringGearOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {touringGearOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === touringGearOptions.length - 1
                        ? "rounded-r-md"
                        : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>

        <div className="mt-28 w-full">
          <button
            type="button"
            onClick={(e) => submitSpot(e)}
            className="w-full md:w-32 rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
          >
            Submit Shop
          </button>
          <p id="total-error" className="mt-2 text-sm text-red-600">
            {submissionError} &nbsp;
          </p>
        </div>
      </form>
    </div>
  );
}
