import React, { useRef, useState, useEffect } from "react";
import Map, {
  Source,
  Layer,
  NavigationControl,
  GeolocateControl,
  Popup,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import CampsitePopup from "./CampsitePopup";

const AdminMapComponent = ({
  allCampsites,
  selectedCampsite,
  onMapLoad,
  mapStyle,
}) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && onMapLoad) {
      onMapLoad(mapRef.current);
    }
  }, [mapRef.current, onMapLoad]);
  const [showPopup, setShowPopup] = useState(false);
  const [showNamePopup, setShowNamePopup] = useState(false);
  const [popupCoordinates, setPopupCoordinates] = useState([-100, 40]);
  const [selectedProperties, setSelectedProperties] = useState(null);
  const [cursorStyle, setCursorStyle] = useState();

  const clusterLayer = {
    id: "clusters",
    type: "circle",
    source: "allData",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": [
        "step",
        ["get", "point_count"],
        "#2ecc71",
        10,
        "#f39c12",
        100,
        "#f1c40f",
        750,
        "#51bbd6",
      ],
      "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      "circle-opacity": 1,
      "circle-stroke-width": 5,
      "circle-stroke-color": [
        "step",
        ["get", "point_count"],
        "#2ecc71",
        10,
        "#f39c12",
        100,
        "#f1c40f",
        750,
        "#51bbd6",
      ],
      "circle-stroke-opacity": 0.3,
    },
  };

  const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "allData",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  };

  const unclusteredApprovedCampsiteLayer = {
    id: "unclustered-point-approved",
    type: "circle",
    source: "allData",
    filter: [
      "all",
      ["!", ["has", "point_count"]],
      ["!", ["get", "isUnapproved"]],
    ],
    paint: {
      "circle-color": [
        "match",
        ["get", "type_of_facility"],
        "campground",
        "#fbb03b",
        "h/b campground",
        "#2ecc71",
        "park",
        "#00FFFF",
        "church",
        "#fff",
        "rv park",
        "#b2bec3",
        "no-turn-away",
        "#e056fd",
        "dispersed",
        "#e056fd",
        "business",
        "#ffff00",
        "hostel",
        "#ffff00",
        "hotel",
        "#ffff00",
        "#4264fb",
      ],
      "circle-radius": 6,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff",
    },
  };

  const unclusteredUnapprovedCampsiteLayer = {
    id: "unclustered-point-unapproved",
    type: "circle",
    source: "allData",
    filter: ["==", ["get", "isUnapproved"], true],
    paint: {
      "circle-color": "#e74c3c",
      "circle-radius": 8,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff",
    },
  };

  const onMouseEnter = (e) => {
    setCursorStyle("pointer");
    if (showPopup) return;

    const features = mapRef.current.queryRenderedFeatures(e.point, {
      layers: ["unclustered-point-approved", "unclustered-point-unapproved"],
    });

    if (features.length > 0) {
      const feature = features[0];
      let coordinates = feature.geometry.coordinates.slice();
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      setSelectedProperties(feature.properties);
      setPopupCoordinates(coordinates);
      setShowNamePopup(true);
    }
  };

  const onMouseLeave = () => {
    setShowNamePopup(false);
    setCursorStyle("");
  };

  const onClick = (e) => {
    if (e.features.length === 0) return;

    const feature = e.features[0];
    if (feature.layer.id === "clusters") {
      const clusterId = feature.properties.cluster_id;
      const mapboxSource = mapRef.current.getSource("allData");

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;

        mapRef.current.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 500,
        });
      });
    } else if (
      feature.layer.id === "unclustered-point-approved" ||
      feature.layer.id === "unclustered-point-unapproved"
    ) {
      let coordinates = feature.geometry.coordinates.slice();
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      mapRef.current.easeTo({
        center: feature.geometry.coordinates,
        duration: 500,
      });

      setSelectedProperties(feature.properties);
      setPopupCoordinates(coordinates);
      setShowPopup(true);
    }
  };

  return (
    <Map
      mapboxAccessToken={process.env.REACT_APP_MAPBOX}
      initialViewState={{
        longitude: -98.1739304110168,
        latitude: 39.55519650847471,
        zoom: 4,
      }}
      reuseMaps
      style={{ width: "100%", height: "100%" }}
      mapStyle={mapStyle || "mapbox://styles/mapbox/streets-v11"}
      ref={mapRef}
      cursor={cursorStyle}
      interactiveLayerIds={[
        clusterLayer.id,
        unclusteredApprovedCampsiteLayer.id,
        unclusteredUnapprovedCampsiteLayer.id,
      ]}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <NavigationControl />
      <GeolocateControl
        positionOptions={{ enableHighAccuracy: true }}
        trackUserLocation={true}
        showUserLocation={true}
      />

      <Source
        id="allData"
        type="geojson"
        data={allCampsites}
        cluster={true}
        clusterMaxZoom={14}
        clusterRadius={30}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...unclusteredApprovedCampsiteLayer} />
        <Layer {...unclusteredUnapprovedCampsiteLayer} />
      </Source>

      {showPopup && (
        <Popup
          longitude={popupCoordinates[0]}
          latitude={popupCoordinates[1]}
          onClose={() => setShowPopup(false)}
          maxWidth="300px"
        >
          <CampsitePopup
            properties={selectedProperties}
            coordinates={popupCoordinates}
          />
        </Popup>
      )}

      {showNamePopup && (
        <Popup
          longitude={popupCoordinates[0]}
          latitude={popupCoordinates[1]}
          closeButton={false}
          maxWidth="240"
          style={{ textAlign: "center" }}
        >
          <h2 className="text-sm">{selectedProperties.name}</h2>
        </Popup>
      )}
    </Map>
  );
};

export default AdminMapComponent;
