import { useState, useRef } from "react";
import { supabase } from "../supabase/client";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Radio,
  RadioGroup,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Map, { NavigationControl, Marker } from "react-map-gl";

// Constants for form options
const tableOptions = ["?", "Yes", "No"];
const shelterOptions = ["?", "Yes", "No"];
const donationOptions = ["?", "Yes", "No"];
const waterOptions = [
  "?",
  "Potable",
  "Non-potable",
  "Natural Source",
  "Yes",
  "No",
];
const waterSeasonalOptions = ["?", "Yes", "No"];
const showerOptions = ["?", "Hot", "Warm", "Cold", "Yes", "No"];
const toiletOptions = [
  "?",
  "Running Water",
  "Pit Toilet",
  "Porta Potty",
  "Yes",
  "No",
];
const trashServiceOptions = ["?", "Yes", "No"];
const electricityOptions = [
  "?",
  "Yes",
  "Yes - at sites",
  "Yes - not at sites",
  "No",
];
const laundryOptions = ["?", "Yes", "No"];
const foodStorageOptions = ["?", "Yes", "No"];
const noTurnAway = ["?", "Yes", "No"];
const wifiOptions = [
  "?",
  "Yes - fast",
  "Yes - average",
  "Yes - slow",
  "Yes - unknown",
  "No",
];
const hammockOptions = ["?", "Yes", "No"];

const costUnit = [
  { name: "Unsure", value: null },
  { name: "Site", value: "site" },
  { name: "Person", value: "person" },
  { name: "Tent", value: "tent" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CampsiteForm({
  marker,
  selectedCountry,
  selectedState,
  setSelectedCountry,
  setSelectedState,
  user,
  setShowForm,
  typeOfFacilityVal,
  editLocation,
  countries,
  usStates,
  provinces,
}) {
  const mapRef = useRef(null);
  const [countryError, setCountryError] = useState();
  const [stateError, setStateError] = useState();
  // Form state
  const [spotName, setSpotName] = useState("");
  const [spotDesc, setSpotDesc] = useState("");
  const [spotWebsite, setSpotWebsite] = useState("");
  const [spotPhone, setSpotPhone] = useState("");
  const [selectedCostUnit, setSelectedCostUnit] = useState(costUnit[0]);
  const [spotCost, setSpotCost] = useState(0);

  // Error states
  const [nameError, setNameError] = useState();
  const [descError, setDescError] = useState();
  const [websiteError, setWebsiteError] = useState();
  const [phoneError, setPhoneError] = useState();
  const [costError, setCostError] = useState();
  const [latError, setLatError] = useState();
  const [submissionError, setSubmissionError] = useState();

  // Amenity states
  const [tableOptionsVal, setTableOptionsVal] = useState(tableOptions[0]);
  const [shelterOptionsVal, setShelterOptionsVal] = useState(shelterOptions[0]);
  const [donationOptionsVal, setDonationOptionsVal] = useState(
    donationOptions[0]
  );
  const [waterOptionsVal, setWaterOptionsVal] = useState(waterOptions[0]);
  const [waterSeasonalOptionsVal, setWaterSeasonalOptionsVal] = useState(
    waterSeasonalOptions[0]
  );
  const [showerOptionsVal, setShowerOptionsVal] = useState(showerOptions[0]);
  const [toiletOptionsVal, setToiletOptionsVal] = useState(toiletOptions[0]);
  const [trashServiceOptionsVal, setTrashServiceOptionsVal] = useState(
    trashServiceOptions[0]
  );
  const [electricityOptionsVal, setElectricityOptionsVal] = useState(
    electricityOptions[0]
  );
  const [laundryOptionsVal, setLaundryOptionsVal] = useState(laundryOptions[0]);
  const [foodStorageOptionsVal, setFoodStorageOptionsVal] = useState(
    foodStorageOptions[0]
  );
  const [noTurnAwayVal, setNoTurnAwayVal] = useState(noTurnAway[0]);
  const [wifiOptionsVal, setWifiOptionsVal] = useState(wifiOptions[0]);
  const [hammockOptionsVal, setHammockOptionsVal] = useState(hammockOptions[0]);

  // Helper functions
  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!urlPattern.test(urlString);
  };

  const cleanPhoneNumber = (phone) => {
    const digitsOnly = phone.replace(/[^\d]/g, "");
    return digitsOnly;
  };

  const translateValues = (val) => {
    if (val === "Yes") return true;
    if (val === "No") return false;
    if (val === "?") return null;
  };

  const nullAndLowercase = (val) => {
    if (val === "?") return null;
    return val.toLowerCase();
  };

  // Form validation
  const validateForm = () => {
    let hasError = false;

    if (spotName.length < 5) {
      setNameError("Name must be more than 5 characters long.");
      hasError = true;
    } else if (spotName.length > 120) {
      setNameError("Name must be less than 120 characters.");
      hasError = true;
    } else {
      setNameError("");
    }

    if (spotDesc.length < 40) {
      setDescError(
        "Description needs to be more than 40 characters. It is currently at: " +
          spotDesc.length
      );
      hasError = true;
    } else if (spotDesc.length > 950) {
      setDescError(
        "Description needs to be less than 950 characters. It is currently at: " +
          spotDesc.length
      );
      hasError = true;
    } else {
      setDescError("");
    }

    if (spotWebsite.length > 0 && !isValidUrl(spotWebsite)) {
      setWebsiteError("Not a valid URL.");
      hasError = true;
    } else if (spotWebsite.length > 200) {
      setWebsiteError("URL is too long. Must be less than 200 characters.");
    } else {
      setWebsiteError("");
    }

    const cleanedPhone = cleanPhoneNumber(spotPhone);
    const isTollFree = /^1(800|888|877|866|855|844|833)/.test(cleanedPhone);

    if (
      spotPhone &&
      ((isTollFree && cleanedPhone.length !== 11) ||
        (!isTollFree && cleanedPhone.length !== 10))
    ) {
      setPhoneError(
        isTollFree
          ? "Toll-free numbers should be 11 digits including the '1'"
          : "Not a valid phone number. Don't include the country code."
      );
      hasError = true;
    } else {
      setPhoneError("");
    }

    if (spotCost < 0) {
      setCostError("The cost needs to be a 0 or greater.");
      hasError = true;
    } else {
      setCostError("");
    }

    if (hasError) {
      setSubmissionError("Fix the errors above.");
    } else {
      setSubmissionError("");
    }

    return hasError;
  };

  // Form submission
  async function submitSpot(e) {
    if (validateForm()) {
      return;
    }

    e.preventDefault();
    const { data, error } = await supabase
      .from("campsites")
      .insert([
        {
          name: spotName,
          state: selectedState.code,
          no_turn_away: translateValues(noTurnAwayVal),
          description: spotDesc,
          cost: spotCost,
          cost_unit: selectedCostUnit.value,
          donation_based: translateValues(donationOptionsVal),
          phone_number: spotPhone ? cleanPhoneNumber(spotPhone) : null,
          latitude: marker.lat,
          longitude: marker.lng,
          tables: translateValues(tableOptionsVal),
          water: nullAndLowercase(waterOptionsVal),
          showers: nullAndLowercase(showerOptionsVal),
          toilet: nullAndLowercase(toiletOptionsVal),
          trash_service: translateValues(trashServiceOptionsVal),
          electricity: nullAndLowercase(electricityOptionsVal),
          laundry: translateValues(laundryOptionsVal),
          wifi: nullAndLowercase(wifiOptionsVal),
          food_storage: translateValues(foodStorageOptionsVal),
          hammocks: translateValues(hammockOptionsVal),
          type_of_facility: typeOfFacilityVal.value,
          picnic_shelter: translateValues(shelterOptionsVal),
          website_url: spotWebsite,
          submitted_by: user.id,
          approved: false,
          water_is_seasonal: translateValues(waterSeasonalOptionsVal),
        },
      ])
      .select();

    if (error) {
      setSubmissionError(
        "There was an error with the submission: " + error.message
      );
      setTimeout(() => {
        setSubmissionError(null);
      }, 3000);
    }

    if (data) {
      setShowForm(false);
    }
  }

  return (
    <div>
      <div className="bg-white px-2 md:px-6 py-12 lg:px-8 md:w-9/12 mx-auto">
        <h1 className="text-4xl font-bold pb-4">Add New Location</h1>
        <form>
          <div className="space-y-12">
            <div className="pb-12">
              <h2 className="text-3xl text-gray-700 font-bold">General</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                This information will be visible by other cyclists. Please don't
                share any personal information, and make things as detailed as
                possible.
              </p>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Site Name
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        value={spotName}
                        onChange={(e) => setSpotName(e.target.value)}
                        id="name"
                        name="name"
                        type="text"
                        className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <p id="name-error" className="mt-2 text-sm text-red-600">
                      {nameError} &nbsp;
                    </p>
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="desc"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="desc"
                      name="desc"
                      value={spotDesc}
                      onChange={(e) => setSpotDesc(e.target.value)}
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <p className="mt-3 text-sm leading-6 text-gray-600">
                    Include any information you'd want to know, like cell
                    signal, required permits, check-in process, road conditions
                    on the way to the site.
                  </p>
                  <p id="desc-error" className="mt-2 text-sm text-red-600">
                    {descError}
                  </p>
                </div>

                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Site Website
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        id="website"
                        name="website"
                        value={spotWebsite}
                        onChange={(e) => setSpotWebsite(e.target.value)}
                        type="url"
                        className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <p id="url-error" className="mt-2 text-sm text-red-600">
                      {websiteError}
                    </p>
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone Number
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        id="phone"
                        name="phone"
                        type="tel"
                        value={spotPhone}
                        onChange={(e) => setSpotPhone(e.target.value)}
                        className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <p id="phone-error" className="mt-2 text-sm text-red-600">
                      {phoneError}
                    </p>
                  </div>
                </div>
                {/* <div className="sm:col-span-4 sm:max-w-md">
                  <Listbox
                    value={typeOfFacilityVal}
                    onChange={setTypeOfFacilityVal}
                  >
                    <Label className="block text-sm font-medium leading-6 text-gray-900">
                      Type of Facility
                    </Label>
                    <div className="relative mt-2">
                      <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span className="block truncate">
                          {typeOfFacilityVal.name}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                          />
                        </span>
                      </ListboxButton>

                      <ListboxOptions
                        transition
                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                      >
                        {typeOfFacility.map((facility, index) => (
                          <ListboxOption
                            key={index}
                            value={facility}
                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                          >
                            <span className="block truncate font-normal group-data-[selected]:font-semibold">
                              {facility.name}
                            </span>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            </span>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                  </Listbox>
                </div> */}
                <div className="sm:col-span-4 sm:max-w-md">
                  <Listbox
                    value={selectedCountry}
                    onChange={(country) => {
                      setSelectedCountry(country);
                      // Reset state/province when country changes
                      if (country.code === "USA") {
                        setSelectedState(usStates[0]);
                      } else if (country.code === "CA") {
                        setSelectedState(provinces[0]);
                      } else {
                        setSelectedState({ name: "Select...", code: "fake" });
                      }
                    }}
                  >
                    <Label className="block text-sm font-medium leading-6 text-gray-900">
                      Country
                    </Label>
                    <div className="relative mt-2">
                      <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span className="block truncate">
                          {selectedCountry.name}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                          />
                        </span>
                      </ListboxButton>

                      <ListboxOptions
                        transition
                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                      >
                        {countries.map((country) => (
                          <ListboxOption
                            key={country.code}
                            value={country}
                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                          >
                            <span className="block truncate font-normal group-data-[selected]:font-semibold">
                              {country.name}
                            </span>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            </span>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                  </Listbox>
                  <p id="country-error" className="mt-2 text-sm text-red-600">
                    {countryError}
                  </p>
                </div>

                {selectedCountry.code !== "fake" && (
                  <div className="sm:col-span-4 sm:max-w-md">
                    <Listbox value={selectedState} onChange={setSelectedState}>
                      <Label className="block text-sm font-medium leading-6 text-gray-900">
                        {selectedCountry.code === "USA" ? "State" : "Province"}
                      </Label>
                      <div className="relative mt-2">
                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <span className="block truncate">
                            {selectedState.name}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              aria-hidden="true"
                              className="h-5 w-5 text-gray-400"
                            />
                          </span>
                        </ListboxButton>

                        <ListboxOptions
                          transition
                          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                        >
                          {(selectedCountry.code === "USA"
                            ? usStates
                            : provinces
                          ).map((state) => (
                            <ListboxOption
                              key={state.code}
                              value={state}
                              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                            >
                              <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                {state.name}
                              </span>

                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon
                                  aria-hidden="true"
                                  className="h-5 w-5"
                                />
                              </span>
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </div>
                    </Listbox>
                    <p id="state-error" className="mt-2 text-sm text-red-600">
                      {stateError}
                    </p>
                  </div>
                )}
                <div className="sm:col-span-4">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Cost ($)
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        id="cost"
                        name="cost"
                        value={spotCost}
                        onChange={(e) => setSpotCost(e.target.value)}
                        type="number"
                        className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <p id="cost-error" className="mt-2 text-sm text-red-600">
                      {costError}
                    </p>
                  </div>
                </div>
                <div className="sm:col-span-4 sm:max-w-md">
                  <Listbox
                    value={selectedCostUnit}
                    onChange={setSelectedCostUnit}
                  >
                    <Label className="block text-sm font-medium leading-6 text-gray-900">
                      Cost Unit
                    </Label>
                    <div className="relative mt-2">
                      <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span className="block truncate">
                          {selectedCostUnit.name}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                          />
                        </span>
                      </ListboxButton>

                      <ListboxOptions
                        transition
                        className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                      >
                        {costUnit.map((unit, index) => (
                          <ListboxOption
                            key={index}
                            value={unit}
                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                          >
                            <span className="block truncate font-normal group-data-[selected]:font-semibold">
                              {unit.name}
                            </span>

                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                              <CheckIcon
                                aria-hidden="true"
                                className="h-5 w-5"
                              />
                            </span>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </div>
                  </Listbox>
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-8 gap-1 my-7 items-center">
                <div>Donation Based</div>
                <div className="col-span-6">
                  <fieldset
                    className="cursor-default"
                    aria-label="Choose a memory option"
                  >
                    <RadioGroup
                      value={donationOptionsVal}
                      onChange={setDonationOptionsVal}
                      className="isolate inline-flex rounded-md shadow-sm"
                    >
                      {donationOptions.map((option, index) => (
                        <Radio
                          key={option}
                          value={option}
                          className={classNames(
                            index === 0 ? "rounded-l-md" : "-ml-px",
                            index === donationOptions.length - 1
                              ? "rounded-r-md"
                              : "",
                            "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                          )}
                        >
                          {option}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-12">
            <div className="pb-12">
              <h2 className="text-3xl text-gray-700 font-bold">Location</h2>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-8">
                <div className="sm:col-span-4">
                  <p>
                    This is how the site you're adding will appear on the map.
                    If it's wrong, please change it.
                  </p>

                  <button
                    type="button"
                    onClick={() => editLocation()}
                    className="w-full md:w-32 rounded-md bg-blue-500 px-3.5 py-2.5 my-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Edit location
                  </button>
                  <br />
                  <label
                    htmlFor="latitude"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Latitude
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        value={marker.lat}
                        disabled={true}
                        id="latitude"
                        name="latitude"
                        type="text"
                        className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <p id="lat-error" className="mt-2 text-sm text-red-600">
                      {latError}
                    </p>
                  </div>
                  <br />
                  <label
                    htmlFor="longitude"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Longitude
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        value={marker.lng}
                        disabled={true}
                        id="longitude"
                        name="longitude"
                        type="text"
                        className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-4 h-96">
                  <Map
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                    reuseMaps
                    width="100%"
                    mapStyle="mapbox://styles/mapbox/streets-v11"
                    initialViewState={{
                      longitude: marker.lng,
                      latitude: marker.lat,
                      zoom: 13,
                    }}
                    ref={mapRef}
                  >
                    <NavigationControl style={{ marginRight: "25px" }} />
                    <Marker latitude={marker.lat} longitude={marker.lng} />
                  </Map>
                </div>
              </div>
            </div>
          </div>
        </form>
        <h2 className="text-3xl text-gray-700 font-bold">Amenities</h2>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Picnic Tables</p>
          </div>
          <div className="col-span-6">
            <fieldset
              className="cursor-default"
              aria-label="Choose a memory option"
            >
              <RadioGroup
                value={tableOptionsVal}
                onChange={setTableOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {tableOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === tableOptions.length - 1 ? "rounded-r-md" : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Picnic Shelter</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={shelterOptionsVal}
                onChange={setShelterOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {shelterOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === shelterOptions.length - 1 ? "rounded-r-md" : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Water</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={waterOptionsVal}
                onChange={setWaterOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {waterOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === waterOptions.length - 1 ? "rounded-r-md" : "",
                      "sm:whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Is water seasonal?</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={waterSeasonalOptionsVal}
                onChange={setWaterSeasonalOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {waterSeasonalOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === waterSeasonalOptions.length - 1
                        ? "rounded-r-md"
                        : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Showers</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={showerOptionsVal}
                onChange={setShowerOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {showerOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === showerOptions.length - 1 ? "rounded-r-md" : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Toilet</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={toiletOptionsVal}
                onChange={setToiletOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {toiletOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === toiletOptions.length - 1 ? "rounded-r-md" : "",
                      "sm:whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Electricity</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={electricityOptionsVal}
                onChange={setElectricityOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {electricityOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === electricityOptions.length - 1
                        ? "rounded-r-md"
                        : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Hammock Accessible</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={hammockOptionsVal}
                onChange={setHammockOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {hammockOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === hammockOptions.length - 1 ? "rounded-r-md" : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Laundry</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={laundryOptionsVal}
                onChange={setLaundryOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {laundryOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === laundryOptions.length - 1 ? "rounded-r-md" : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Wifi</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={wifiOptionsVal}
                onChange={setWifiOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {wifiOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === wifiOptions.length - 1 ? "rounded-r-md" : "",
                      "sm:whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Food Storage Container</p>
          </div>
          <div className="col-span-8">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={foodStorageOptionsVal}
                onChange={setFoodStorageOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {foodStorageOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === foodStorageOptions.length - 1
                        ? "rounded-r-md"
                        : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>Trash Service</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={trashServiceOptionsVal}
                onChange={setTrashServiceOptionsVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {trashServiceOptions.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === trashServiceOptions.length - 1
                        ? "rounded-r-md"
                        : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-1 my-7 items-center">
          <div className="col-span-2">
            <p>No Turn Away</p>
          </div>
          <div className="col-span-6">
            <fieldset
              aria-label="Choose a memory option"
              className="cursor-default"
            >
              <RadioGroup
                value={noTurnAwayVal}
                onChange={setNoTurnAwayVal}
                className="isolate inline-flex rounded-md shadow-sm"
              >
                {noTurnAway.map((option, index) => (
                  <Radio
                    key={option}
                    value={option}
                    className={classNames(
                      index === 0 ? "rounded-l-md" : "-ml-px",
                      index === noTurnAway.length - 1 ? "rounded-r-md" : "",
                      "whitespace-nowrap relative inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-indigo-600 data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-indigo-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-indigo-500 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset"
                    )}
                  >
                    {option}
                  </Radio>
                ))}
              </RadioGroup>
            </fieldset>
          </div>
        </div>
        <div className="mt-28 w-full">
          <button
            type="button"
            onClick={(e) => submitSpot(e)}
            className="w-full md:w-32 rounded-md bg-orange-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
          >
            Submit Spot
          </button>
          <p id="total-error" className="mt-2 text-sm text-red-600">
            {submissionError} &nbsp;
          </p>
        </div>
      </div>
    </div>
  );
}
