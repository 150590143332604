import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { supabase } from "../supabase/client";
import { Navigate } from "react-router-dom";
import AdminMapComponent from "../Components/MapComponents/AdminMapComponent";

export function AdminView() {
  const { user } = useAuth();
  const [unapprovedCampsites, setUnapprovedCampsites] = useState([]);
  const [selectedCampsite, setSelectedCampsite] = useState(null);
  const [allCampsites, setAllCampsites] = useState({ features: [] });
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [editedDescription, setEditedDescription] = useState("");
  const [mapStyle, setMapStyle] = useState(
    "mapbox://styles/mapbox/streets-v11"
  );

  async function fetchUnapprovedCampsites() {
    const { data, error } = await supabase
      .from("campsites")
      .select(
        `
        *,
        profiles (
          username
        )
      `
      )
      .eq("approved", false)
      .order("created_at", { ascending: false });

    if (error) {
      console.error("Error fetching unapproved campsites:", error);
      return;
    }

    setUnapprovedCampsites(data);
    setLoading(false);
  }

  async function fetchAllCampsites() {
    // Only fetch approved campsites
    const { data: approvedData, error } = await supabase
      .from("campsites")
      .select()
      .eq("approved", true);

    if (error) {
      console.error("Error fetching approved campsites:", error);
      return;
    }

    // Convert to GeoJSON
    let geojson = { features: [], type: "FeatureCollection" };

    // Add approved campsites
    for (let campsite of approvedData || []) {
      let tempCampsite = {
        type: "Feature",
        properties: { ...campsite },
        geometry: {
          coordinates: [campsite.longitude, campsite.latitude],
          type: "Point",
        },
        id: campsite.id.toString(),
      };
      geojson.features.push(tempCampsite);
    }

    // Add selected unapproved campsite if one is selected
    if (selectedCampsite) {
      geojson.features.push({
        type: "Feature",
        properties: { ...selectedCampsite, isUnapproved: true },
        geometry: {
          coordinates: [selectedCampsite.longitude, selectedCampsite.latitude],
          type: "Point",
        },
        id: selectedCampsite.id.toString(),
      });
    }

    setAllCampsites(geojson);
  }

  useEffect(() => {
    if (user && user.email === "mccullough.spencer@gmail.com") {
      fetchUnapprovedCampsites();
      fetchAllCampsites();
    }
  }, [user]);

  // Only allow access to specific email
  if (!user || user.email !== "mccullough.spencer@gmail.com") {
    return <Navigate to="/" replace />;
  }

  function handleCampsiteSelect(campsite) {
    setSelectedCampsite(campsite);
    setEditedDescription(campsite.description || "");
    // Update map to include the selected unapproved campsite
    setAllCampsites((prev) => ({
      ...prev,
      features: [
        ...prev.features.filter((f) => !f.properties.isUnapproved),
        {
          type: "Feature",
          properties: { ...campsite, isUnapproved: true },
          geometry: {
            coordinates: [campsite.longitude, campsite.latitude],
            type: "Point",
          },
          id: campsite.id.toString(),
        },
      ],
    }));

    // Center map on selected campsite
    if (mapInstance) {
      mapInstance.flyTo({
        center: [campsite.longitude, campsite.latitude],
        zoom: 12,
        duration: 1000,
      });
    }
  }

  function formatDate(dateString) {
    return new Date(dateString).toLocaleDateString();
  }

  return (
    <div className="flex h-screen">
      {/* Left sidebar with unapproved campsites */}
      <div className="w-1/3 bg-white border-r border-gray-200 overflow-y-auto p-4">
        <h2 className="text-2xl font-bold mb-4">Unapproved Campsites</h2>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div className="space-y-4">
            {unapprovedCampsites.map((campsite) => (
              <div
                key={campsite.id}
                className={`p-4 rounded-lg cursor-pointer transition-colors ${
                  selectedCampsite?.id === campsite.id
                    ? "bg-blue-100"
                    : "bg-gray-50 hover:bg-gray-100"
                }`}
                onClick={() => handleCampsiteSelect(campsite)}
              >
                <h3 className="font-semibold">{campsite.name}</h3>
                <p className="text-sm text-gray-600">{campsite.state}</p>
                <p className="text-xs text-gray-500">
                  Added by {campsite.profiles?.username || "unknown"} on{" "}
                  {formatDate(campsite.created_at)}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Right side with map and details */}
      <div className="flex-1 flex flex-col">
        {/* Map section */}
        <div className="h-1/2 relative">
          <AdminMapComponent
            allCampsites={allCampsites}
            selectedCampsite={selectedCampsite}
            onMapLoad={setMapInstance}
            mapStyle={mapStyle}
          />
        </div>

        {/* Details section */}
        <div className="h-1/2 overflow-y-auto p-6">
          {selectedCampsite ? (
            <div>
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h2 className="text-2xl font-bold">
                    {selectedCampsite.name}
                  </h2>
                  <a
                    href={`https://supabase.com/dashboard/project/bvkqszxvecjnhzzoempo/editor/29213?schema=public&filter=id%3Aeq%3A${selectedCampsite.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm text-blue-600 hover:underline"
                  >
                    View in Supabase
                  </a>
                </div>
                <button
                  onClick={() =>
                    setMapStyle((prev) =>
                      prev === "mapbox://styles/mapbox/streets-v11"
                        ? "mapbox://styles/mapbox/satellite-v9"
                        : "mapbox://styles/mapbox/streets-v11"
                    )
                  }
                  className="bg-white border border-gray-300 px-3 py-1.5 rounded-md text-sm hover:bg-gray-50"
                >
                  {mapStyle.includes("satellite")
                    ? "Street View"
                    : "Satellite View"}
                </button>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <h3 className="font-semibold mb-2">Details</h3>
                  <dl className="space-y-2">
                    <div>
                      <dt className="font-medium">State</dt>
                      <dd>{selectedCampsite.state}</dd>
                    </div>
                    <div>
                      <dt className="font-medium">Type of Facility</dt>
                      <dd>{selectedCampsite.type_of_facility}</dd>
                    </div>
                    <div>
                      <dt className="font-medium">Cost</dt>
                      <dd>
                        {selectedCampsite.donation_based
                          ? "Donation based"
                          : selectedCampsite.cost
                          ? `$${selectedCampsite.cost}/${selectedCampsite.cost_unit}`
                          : "Free"}
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium">GPS</dt>
                      <dd>
                        {selectedCampsite.latitude},{" "}
                        {selectedCampsite.longitude}
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium">Website</dt>
                      <dd>
                        {selectedCampsite.website_url ? (
                          <a
                            href={selectedCampsite.website_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline"
                          >
                            Visit website
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium">Phone</dt>
                      <dd>{selectedCampsite.phone_number || "N/A"}</dd>
                    </div>
                  </dl>
                </div>
                <div>
                  <h3 className="font-semibold mb-2">Amenities</h3>
                  <dl className="space-y-2">
                    <div>
                      <dt className="font-medium">Water</dt>
                      <dd>{selectedCampsite.water || "Unknown"}</dd>
                    </div>
                    <div>
                      <dt className="font-medium">Showers</dt>
                      <dd>{selectedCampsite.showers || "Unknown"}</dd>
                    </div>
                    <div>
                      <dt className="font-medium">Toilet</dt>
                      <dd>{selectedCampsite.toilet || "Unknown"}</dd>
                    </div>
                    <div>
                      <dt className="font-medium">Picnic Tables</dt>
                      <dd>{selectedCampsite.tables ? "Yes" : "No"}</dd>
                    </div>
                    <div>
                      <dt className="font-medium">Picnic Shelter</dt>
                      <dd>{selectedCampsite.picnic_shelter ? "Yes" : "No"}</dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="mt-6">
                <h3 className="font-semibold mb-2">Description</h3>
                <textarea
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                  className="w-full h-32 p-2 border rounded-md"
                />
              </div>
              <div className="mt-6 flex gap-4">
                <button
                  onClick={async () => {
                    setActionLoading(true);
                    const { error } = await supabase
                      .from("campsites")
                      .update({
                        approved: true,
                        description: editedDescription,
                      })
                      .eq("id", selectedCampsite.id);
                    if (error) {
                      console.error("Error approving campsite:", error);
                      alert("Error approving campsite");
                    } else {
                      // Remove from unapproved list and clear selection
                      setUnapprovedCampsites((prev) =>
                        prev.filter((c) => c.id !== selectedCampsite.id)
                      );
                      setSelectedCampsite(null);
                      // Refresh the map
                      fetchAllCampsites();
                    }
                    setActionLoading(false);
                  }}
                  disabled={actionLoading}
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                >
                  {actionLoading ? "Processing..." : "Approve"}
                </button>
                <button
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this campsite?"
                      )
                    ) {
                      setActionLoading(true);
                      const { error } = await supabase
                        .from("campsites")
                        .delete()
                        .eq("id", selectedCampsite.id);

                      if (error) {
                        console.error("Error deleting campsite:", error);
                        alert("Error deleting campsite");
                      } else {
                        // Remove from unapproved list and clear selection
                        setUnapprovedCampsites((prev) =>
                          prev.filter((c) => c.id !== selectedCampsite.id)
                        );
                        setSelectedCampsite(null);
                        // Refresh the map
                        fetchAllCampsites();
                      }
                      setActionLoading(false);
                    }
                  }}
                  disabled={actionLoading}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                >
                  {actionLoading ? "Processing..." : "Delete"}
                </button>
              </div>
            </div>
          ) : (
            <div className="text-center text-gray-500">
              Select a campsite to view details
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
