import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";

import { AdminView } from "./Routes/AdminView";
import { DonateView } from "./Routes/DonateView";
import { LoginView } from "./Routes/LoginView";
import { LandingView } from "./Routes/LandingView";
import { RegisterView } from "./Routes/RegisterView";
import { AboutView } from "./Routes/AboutView";
import { AddNewView } from "./Routes/AddNewView";
import { RequestsView } from "./Routes/RequestsView";
import { CheckinView } from "./Routes/CheckinView";
import { PostRegistrationView } from "./Routes/PostRegistrationView";
import { CampsiteView } from "./Routes/CampsiteView";
import { BikeShopView } from "./Routes/BikeShopView";
import { MapView } from "./Routes/MapView";
import { PageNotFoundView } from "./Routes/PageNotFoundView";
import { NoTurnAwayView } from "./Routes/NoTurnAwayView";
import { CampingCoalitionView } from "./Routes/CampingCoalitionView";
import { OrgsView } from "./Routes/OrgsView";
import ProfileView from "./Routes/ProfileView";
import RootView from "./Routes/RootView";
import CollectionView from "./Routes/CollectionView";
import PublicCollectionView from "./Routes/PublicCollectionView";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import { supabase } from "./supabase/client";
import AuthRoute from "./Components/routeGuards/AuthRoute";
import AnonRoute from "./Components/routeGuards/AnonRoute";

const App = () => {
  const [allCampsites, setAllCampsites] = useState({ features: [] });
  const [bikeShops, setBikeShops] = useState({
    features: [],
    type: "FeatureCollection",
  });
  const [isLoading, setLoading] = useState(true);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<RootView />}>
          <Route
            path="/"
            element={
              <LandingView allCampsites={allCampsites} isLoading={isLoading} />
            }
          />
          <Route
            path="/map"
            element={
              <MapView allCampsites={allCampsites} bikeShopData={bikeShops} />
            }
          />
          <Route path="*" element={<PageNotFoundView />} />
          <Route path="/welcome" element={<PostRegistrationView />} />
          <Route path="/donate" element={<DonateView />} />
          <Route path="/requests" element={<RequestsView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/noturnaway" element={<NoTurnAwayView />} />
          <Route path="/coalition" element={<CampingCoalitionView />} />
          <Route path="/orgs" element={<OrgsView />} />
          <Route path="/campsite/:id" element={<CampsiteView />} />
          <Route path="/bikeshop/:id" element={<BikeShopView />} />
          <Route path="/collection/:id" element={<PublicCollectionView />} />
          <Route path="/admin" element={<AdminView />} />
        </Route>
        <Route element={<AuthRoute />}>
          <Route element={<RootView />}>
            <Route path="/checkin" element={<CheckinView />} />
            <Route
              path="/addNew"
              element={<AddNewView allCampsites={allCampsites} />}
            />
            <Route path="/profile" element={<ProfileView />} />
            <Route path="/manage/collection/:id" element={<CollectionView />} />
          </Route>
        </Route>
        <Route element={<AnonRoute />}>
          <Route element={<RootView />}>
            <Route path="/login" element={<LoginView />} />
            <Route path="/signup" element={<RegisterView />} />
          </Route>
        </Route>
      </Route>
    )
  );

  useEffect(() => {
    getAllCampsites();
    getAllBikeShops();
  }, []);

  async function getAllBikeShops() {
    const { data, error } = await supabase
      .from("bike_shops")
      .select()
      .eq("approved", true);

    if (error) {
      console.error("Error fetching bike shops:", error);
      return;
    }

    let geojson = { features: [], type: "FeatureCollection" };
    for (let shop of data || []) {
      let tempShop = {
        type: "Feature",
        properties: { ...shop },
        geometry: {
          coordinates: [shop.longitude, shop.latitude],
          type: "Point",
        },
        id: shop.id.toString(),
      };
      geojson.features.push(tempShop);
    }

    setBikeShops(geojson);
  }

  async function getAllCampsites() {
    const { data } = await supabase
      .from("campsites")
      .select()
      .eq("approved", true);
    let geojson = { features: [], type: "FeatureCollection" };
    for (let i = 0; i < data.length; i++) {
      let tempCampsite = {
        type: "Feature",
        properties: { ...data[i] },
        geometry: {
          coordinates: [data[i].longitude, data[i].latitude],
          type: "Point",
        },
        id: data[i].id.toString(),
      };
      geojson.features.push(tempCampsite);
    }

    setLoading(false);
    setAllCampsites(geojson);
  }

  return (
    <HelmetProvider>
      <AuthProvider>
        <RouterProvider router={router}></RouterProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};
export default App;
