import { useState, useRef, useEffect } from "react";
import { Link, ScrollRestoration } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { isMobile } from "react-device-detect";
import GeocoderControl from "../Components/MapComponents/GeocoderControl";
import Map, {
  NavigationControl,
  GeolocateControl,
  Source,
  Marker,
  Popup,
  Layer,
} from "react-map-gl";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import "../styles/App.css";
import CampsiteForm from "../Components/CampsiteForm";
import BikeShopForm from "../Components/BikeShopForm";

const countries = [
  { name: "Select a country...", code: "fake" },
  { name: "United States", code: "USA" },
  { name: "Canada", code: "CA" },
];

const provinces = [
  { name: "Select a province...", code: "fake" },
  { name: "Alberta", code: "AB" },
  { name: "British Columbia", code: "BC" },
  { name: "Manitoba", code: "MB" },
  { name: "New Brunswick", code: "NB" },
  { name: "Newfoundland and Labrador", code: "NL" },
  { name: "Nova Scotia", code: "NS" },
  { name: "Ontario", code: "ON" },
  { name: "Prince Edward Island", code: "PE" },
  { name: "Quebec", code: "QC" },
  { name: "Saskatchewan", code: "SK" },
  { name: "Northwest Territories", code: "NT" },
  { name: "Nunavut", code: "NU" },
  { name: "Yukon", code: "YT" },
];

const usStates = [
  { name: "Select a state...", code: "fake" },
  { name: "Alabama", code: "AL" },
  { name: "Alaska", code: "AK" },
  { name: "Arizona", code: "AZ" },
  { name: "Arkansas", code: "AR" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Hawaii", code: "HI" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Iowa", code: "IA" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Maine", code: "ME" },
  { name: "Maryland", code: "MD" },
  { name: "Massachusetts", code: "MA" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Mississippi", code: "MS" },
  { name: "Missouri", code: "MO" },
  { name: "Montana", code: "MT" },
  { name: "Nebraska", code: "NE" },
  { name: "Nevada", code: "NV" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "New York", code: "NY" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania", code: "PA" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "Utah", code: "UT" },
  { name: "Vermont", code: "VT" },
  { name: "Virginia", code: "VA" },
  { name: "Washington", code: "WA" },
  { name: "West Virginia", code: "WV" },
  { name: "Wisconsin", code: "WI" },
  { name: "Wyoming", code: "WY" },
];

const typeOfFacility = [
  {
    name: "H/B Campground",
    value: "h/b campground",
    description: "Hiker/Biker specific camping area.",
    criteria:
      "This has to be a campground or a site in a campground that is set aside just for cyclists. No one that shows up with a car can camp here.",
    guidance: "",
  },
  {
    name: "Campground",
    value: "campground",
    description: "Your standard campground.",
    criteria: "",
    guidance: "",
  },
  {
    name: "Park",
    value: "park",
    description: "A park that allows cyclists to camp there.",
    criteria:
      "This is usually like a baseball field or little municipal park that the town allows touring cyclists to sleep in.",
    guidance:
      "Please outline all information, like who you might need to call to let them know you're here.",
  },
  {
    name: "RV Park",
    value: "rv park",
    description: "Primarily for RVs but may accommodate tents.",
    criteria: "",
    guidance:
      "A lot of RV Parks don't allow tent camping, so feel free to add the ones that do even if they don't have a cyclist specific price.",
  },
  {
    name: "Dispersed",
    value: "dispersed",
    description: "Undeveloped camping area with minimal or no facilities.",
    criteria: "",
    guidance: "",
  },
  {
    name: "Business",
    value: "business",
    description:
      "Commercial establishment that allows informal camping for cyclists..",
    criteria: "This could be a gas station with some grass out back.",
    guidance: "",
  },
  {
    name: "Church",
    value: "church",
    description: "Religious facility that hosts cyclists.",
    criteria:
      "Make sure to add information like who you need to call ahead of time to stay here, if that's the case.",
    guidance: "",
  },
  {
    name: "Hostel",
    value: "hostel",
    description: "Budget-friendly shared accommodation.",
    criteria: "",
    guidance: "",
  },
  {
    name: "Hotel",
    value: "hotel",
    description: "Your standard hotel.",
    criteria:
      "Only add hotels if they offer cyclist specific rates, or are the only place to stay in the whole area and were supportive of you bringing your bike in the room.",
    guidance: "",
  },
  {
    name: "Miscellaneous",
    value: "miscellaneous",
    description: "Other types of camping facilities.",
    criteria: "",
    guidance: "",
  },
  {
    name: "Bike Shop",
    value: "bike_shop",
    description: "Bike shops that are great resources for touring cyclists.",
    criteria: "",
    guidance: "",
  },
];

export function AddNewView({ allCampsites }) {
  const { user } = useAuth();
  const [showForm, setShowForm] = useState(true);
  const [currentStep, setCurrentStep] = useState("map");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);
  const [typeOfFacilityVal, setTypeOfFacilityVal] = useState(null);
  const [expandedFacility, setExpandedFacility] = useState(null);

  const [lng] = useState(-98.1739304110168);
  const [lat] = useState(39.55519650847471);
  const [zoom] = useState(4);

  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [selectedState, setSelectedState] = useState(usStates[0]);

  const [marker, setMarker] = useState(null);
  const [pastedCoords, setPastedCoords] = useState("");
  const [pastedCoordsErr, setPastedCoordsErr] = useState("");

  const mapRef = useRef(null);

  const [cursorStyle, setCursorStyle] = useState();
  const [showNamePopup, setShowNamePopup] = useState(false);
  const [popupCoordinates, setPopupCoordinates] = useState([-100, 40]);
  const [campsiteProperties, setCampsiteProperties] = useState({
    description: "",
  });

  let [selectedFiltersState, setSelectedFiltersState] = useState([
    [
      "all",
      ["!", ["has", "point_count"]],
      [">=", ["coalesce", ["to-number", ["get", "cost"]], 0], 0],
    ],
  ]);

  const clusterLayer = {
    id: "clusters",
    type: "circle",
    source: "campsiteData",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": [
        "step",
        ["get", "point_count"],
        "#2ecc71",
        10,
        "#f39c12",
        100,
        "#f1c40f",
        750,
        "#51bbd6",
      ],
      "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      "circle-opacity": 1,
      "circle-stroke-width": 5,
      "circle-stroke-color": [
        "step",
        ["get", "point_count"],
        "#2ecc71",
        10,
        "#f39c12",
        100,
        "#f1c40f",
        750,
        "#51bbd6",
      ],
      "circle-stroke-opacity": 0.3,
    },
  };

  const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "campsiteData",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  };

  const unclusteredPointLayer = {
    id: "unclustered-point",
    type: "circle",
    source: "campsiteData",
    paint: {
      "circle-color": [
        "match",
        ["get", "type_of_facility"],
        "campground",
        "#fbb03b",
        "h/b campground",
        "#2ecc71",
        "park",
        "#00FFFF",
        "church",
        "#fff",
        "rv park",
        "#b2bec3",
        "no-turn-away",
        "#e056fd",
        "dispersed",
        "#e056fd",
        "business",
        "#ffff00",
        "hostel",
        "#ffff00",
        "hotel",
        "#ffff00",
        "#4264fb",
      ],
      "circle-radius": 6,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#ffffff",
    },
    filter: selectedFiltersState[0],
  };

  const handleMapClick = (event) => {
    const lat = event.lngLat.lat;
    const lng = event.lngLat.lng;
    setMarker({ lng, lat });
  };

  const addViaCoords = (coordsStr) => {
    const coordsArray = coordsStr.split(",");
    if (
      !coordsStr ||
      coordsArray.length !== 2 ||
      (coordsArray[0] <= -90 && coordsArray[0] >= 90) ||
      isNaN(coordsArray[0]) ||
      isNaN(coordsArray[1])
    ) {
      setPastedCoordsErr(
        "Please paste valid coords. Look at the placeholder text for an example of how the data should look."
      );
      return;
    }
    coordsArray[0] = coordsArray[0].trim();
    coordsArray[1] = coordsArray[1].trim();
    setMarker({ lng: Number(coordsArray[1]), lat: Number(coordsArray[0]) });
    mapRef.current.easeTo({
      center: [coordsArray[1], coordsArray[0]],
      zoom: 13,
      duration: 500,
    });
    setPastedCoordsErr("");
  };

  const addSite = () => {
    window.scrollTo(0, 0);
    setCurrentStep("facility");
  };

  const editLocation = () => {
    window.scrollTo(0, 0);
    setCurrentStep("map");
  };

  const onMouseEnter = (e) => {
    setCursorStyle("pointer");
    if (isMobile) {
      return;
    }

    const features = mapRef.current.queryRenderedFeatures(e.point, {
      layers: ["unclustered-point"],
    });

    if (features.length > 0) {
      const feature = features[0];
      let coordinates = feature.geometry.coordinates.slice();
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      setCampsiteProperties(feature.properties);
      setPopupCoordinates(coordinates);
      setShowNamePopup(true);
    }
  };

  const onMouseLeave = (e) => {
    setShowNamePopup(false);
    setCursorStyle("");
  };

  const facilityTypeSelection = () => {
    return (
      <div className="bg-white px-2 md:px-6 py-12 lg:px-8 md:w-9/12 mx-auto">
        <h1 className="text-4xl font-bold pb-4">Add New Location</h1>
        <h2 className="text-3xl text-gray-700 font-bold">
          Select Facility Type
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600 mb-8">
          Choose the type of facility you want to add.
        </p>

        <div className="space-y-4">
          {typeOfFacility.map((facility) => (
            <div
              key={facility.value}
              className={`relative flex flex-col p-4 border rounded-lg cursor-pointer transition-colors ${
                typeOfFacilityVal?.value === facility.value
                  ? "border-indigo-600 bg-indigo-50"
                  : "border-gray-200 hover:border-indigo-300"
              }`}
              onClick={() => setTypeOfFacilityVal(facility)}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    type="radio"
                    checked={typeOfFacilityVal?.value === facility.value}
                    onChange={() => setTypeOfFacilityVal(facility)}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label className="ml-3 block text-md font-medium leading-6 text-gray-900">
                    {facility.name}
                  </label>
                </div>
                {(facility.criteria || facility.guidance) && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpandedFacility(
                        expandedFacility === facility.value
                          ? null
                          : facility.value
                      );
                    }}
                    className="ml-2 p-1 hover:bg-gray-100 rounded-full"
                  >
                    <ChevronDownIcon
                      className={`h-5 w-5 transform transition-transform ${
                        expandedFacility === facility.value ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                )}
              </div>

              {/* Description is now outside the accordion */}
              <p className="mt-1 ml-7 text-sm text-gray-500">
                {facility.description}
              </p>

              {/* Criteria and Guidance remain in the accordion */}
              <div
                className={`mt-2 ml-7 space-y-2 ${
                  expandedFacility === facility.value ? "" : "hidden"
                }`}
              >
                {facility.criteria && (
                  <div>
                    <p className="text-sm font-semibold text-gray-700">
                      Criteria:
                    </p>
                    <p className="text-sm text-gray-500">{facility.criteria}</p>
                  </div>
                )}
                {facility.guidance && (
                  <div>
                    <p className="text-sm font-semibold text-gray-700">
                      Guidance:
                    </p>
                    <p className="text-sm text-gray-500">{facility.guidance}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <br />
        <button
          type="button"
          onClick={() => setCurrentStep("details")}
          disabled={!typeOfFacilityVal}
          className={`rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
            typeOfFacilityVal
              ? "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
              : "bg-gray-400 cursor-not-allowed"
          }`}
        >
          Continue
        </button>
      </div>
    );
  };

  const theForm = () => {
    if (currentStep === "map") {
      return (
        <div>
          <div className="bg-white px-2 md:px-6 py-12 lg:px-8 md:w-9/12 mx-auto">
            <div>
              <h1 className="text-4xl font-bold pb-4">Add New Location</h1>
              <h2 className="text-3xl text-gray-700 font-bold">Drop a pin</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Click on the map to add the campsite. Or paste the coordinates
                below.
              </p>
              <br />
              <label
                htmlFor="latitude"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Latitude/Longitude
              </label>
              <div className="mt-2 flex gap-4 justify-start">
                <div className="w-3/5 md:w-4/5 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    value={pastedCoords}
                    onChange={(e) => setPastedCoords(e.target.value)}
                    id="latitude"
                    name="latitude"
                    placeholder="39.76559324876378, -104.97843706894767"
                    type="text"
                    className="block w-full border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => addViaCoords(pastedCoords)}
                  className="w-2/5 md:w-1/5 rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                >
                  Find Location
                </button>
              </div>
              <p id="lat-error" className="mt-2 text-sm text-red-600">
                {pastedCoordsErr}
              </p>
              <br />
              <div style={{ height: "80vh" }}>
                <Map
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                  reuseMaps
                  width="100%"
                  mapStyle="mapbox://styles/mapbox/streets-v11"
                  initialViewState={{
                    longitude: lng,
                    latitude: lat,
                    zoom: zoom,
                  }}
                  cursor={cursorStyle}
                  ref={mapRef}
                  onClick={handleMapClick}
                  interactiveLayerIds={[
                    clusterLayer.id,
                    unclusteredPointLayer.id,
                  ]}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                  <NavigationControl style={{ marginRight: "25px" }} />
                  <GeolocateControl
                    positionOptions={{ enableHighAccuracy: true }}
                    trackUserLocation={true}
                    showUserLocation={true}
                    auto={true}
                    style={{ marginRight: "25px" }}
                  />
                  <GeocoderControl
                    mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                    position="top-left"
                  />
                  <Source
                    id="campsiteData"
                    type="geojson"
                    data={allCampsites}
                    cluster={true}
                    clusterMaxZoom={14}
                    clusterRadius={30}
                  >
                    <Layer {...clusterLayer} />
                    <Layer {...clusterCountLayer} />
                    <Layer {...unclusteredPointLayer} />
                    {marker && (
                      <Marker
                        latitude={marker.lat}
                        longitude={marker.lng}
                      ></Marker>
                    )}
                    {marker && (
                      <Popup
                        key={marker.lat + marker.lng}
                        latitude={marker.lat}
                        longitude={marker.lng}
                        onClose={() => setMarker(null)}
                      >
                        <div className="p-2">
                          <p className="mb-2 text-sm font-semibold">
                            Coordinates:
                          </p>
                          <p>Longitude: {marker.lng.toFixed(6)}</p>
                          <p className="mb-3">
                            Latitude: {marker.lat.toFixed(6)}
                          </p>
                          <button
                            type="button"
                            onClick={() => addSite()}
                            className="w-full md:w-32 rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                          >
                            Add Location
                          </button>
                        </div>
                      </Popup>
                    )}
                    {showNamePopup && (
                      <Popup
                        key={popupCoordinates[0] + popupCoordinates[1]}
                        longitude={popupCoordinates[0]}
                        latitude={popupCoordinates[1]}
                        closeButton={false}
                        maxWidth="240"
                        style={{ textAlign: "center" }}
                      >
                        <h2 className="text-sm">{campsiteProperties.name}</h2>
                      </Popup>
                    )}
                  </Source>
                </Map>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (currentStep === "facility") {
      return facilityTypeSelection();
    }

    return typeOfFacilityVal.value === "bike_shop" ? (
      <BikeShopForm
        marker={marker}
        selectedCountry={selectedCountry}
        selectedState={selectedState}
        setSelectedCountry={setSelectedCountry}
        setSelectedState={setSelectedState}
        user={user}
        setShowForm={setShowForm}
        editLocation={editLocation}
        countries={countries}
        usStates={usStates}
        provinces={provinces}
      />
    ) : (
      <CampsiteForm
        marker={marker}
        selectedCountry={selectedCountry}
        selectedState={selectedState}
        setSelectedCountry={setSelectedCountry}
        setSelectedState={setSelectedState}
        user={user}
        setShowForm={setShowForm}
        typeOfFacilityVal={typeOfFacilityVal}
        editLocation={editLocation}
        countries={countries}
        usStates={usStates}
        provinces={provinces}
      />
    );
  };

  const thankYouMessage = () => {
    return (
      <main
        className="grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
        style={{ minHeight: "80%" }}
      >
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Thank you for your submission!
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Our moderators are going to look at it shortly, and if approved
            it'll end up on the map. <br />
            If the mods have any questions they'll reach out.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go home
            </Link>
          </div>
        </div>
      </main>
    );
  };

  return (
    <>
      <ScrollRestoration />
      {showForm ? theForm() : thankYouMessage()}
    </>
  );
}
